import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './LoadingButton.scss';

class LoadingButton extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLoadingText: PropTypes.string,
    buttonLoadingTextClassName: PropTypes.string,
    buttonLoadingSpinnerClassName: PropTypes.string,
    buttonLoadingSpinnerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    buttonLoadingText: 'Loading',
    buttonLoadingTextClassName: '',
    buttonLoadingSpinnerClassName: '',
    buttonLoadingSpinnerProps: '',
  };

  render() {
    const {
      isLoading,
      buttonText,
      buttonLoadingText,
      buttonLoadingTextClassName,
      buttonLoadingSpinnerClassName,
      buttonLoadingSpinnerProps,
      ...props
    } = this.props;
    const buttonLoadingTextComponent = (
      <span className={cn([s.buttonLoadingTextClassName, buttonLoadingTextClassName])}>
        {buttonLoadingText}
        <span className={cn([s.buttonLoadingSpinnerClassName, buttonLoadingSpinnerClassName])}>
          <ClipLoader color="white" {...buttonLoadingSpinnerProps} />
        </span>
      </span>
    );
    return (
      <Button
        disabled={isLoading}
        {...props}
      >
        {isLoading ? buttonLoadingTextComponent : buttonText}
      </Button>
    );
  }
}

export default withStyles(s)(LoadingButton);
