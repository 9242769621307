const swal = typeof window === 'undefined' ? null : require('sweetalert2');

export default function SweetAlert(arg) {
  return swal(arg);
}

export function SuccessAlert(message) {
  return SweetAlert({
    title: 'Success',
    html: message,
    type: 'success',
  });
}

export function ErrorAlert(message) {
  return SweetAlert({
    title: 'Error',
    html: message,
    type: 'error',
  });
}

export function WarnAlert(message) {
  return SweetAlert({
    title: 'Warning',
    html: message,
    type: 'warning',
  });
}
