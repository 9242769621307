exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OP9Vv{position:relative}._1g8e8{position:absolute;top:-10%;right:-50px}", ""]);

// exports
exports.locals = {
	"buttonLoadingTextClassName": "OP9Vv",
	"buttonLoadingSpinnerClassName": "_1g8e8"
};