import Parse from 'parse';
import { WarnAlert } from 'helpers/alert';
import history from 'core/history';

export default function handleParseError(cb) {
  const callback = cb || (() => {});
  return (error) => {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        WarnAlert('Your session has expired. Please login again.');
        history.push('/logout?redirect=login');
        break;

      // Other Parse API errors that you want to explicitly handle
      default:
        callback(error);
        break;
    }
  };
}
